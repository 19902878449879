<template>
  <div>
    <navebar></navebar>

    <div v-if="informationFilm !== null" class="position">
      <iframe
        v-if="this.trailer != null"
        width="560"
        height="315"
        :src="trailer"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <div>
        <div class="flex">
          <p><img :src="getUrlPict2()" alt="" class="pict" /></p>
          <div class="align">
            <h2>{{ informationFilm.title }}</h2>
            <p>
              {{ informationFilm.original_title }}({{
                informationFilm.original_language
              }}), Sortie : {{ informationFilm.release_date }}
            </p>
            <p>{{ informationFilm.overview }}</p>
            <p>Informations :</p>
            <ul>
              <li>Popularité :{{ informationFilm.popularity }}</li>
              <li>Note : {{ informationFilm.vote_average }}/10</li>
              <li>Nombre de votes : {{ informationFilm.vote_count }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div>
        <button class="ajout">Ajouter aux favoris</button>
        <button class="supprimer">Supprimer des favoris</button>
        <button class="ajout">Ajouter aux films "A Voir"</button>
        <button class="supprimer">Supprimer des films "A Voir"</button>
      </div> -->
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

import navebar from "@/components/Navebar";
export default {
  name: "Accueil",
  data() {
    return {
      informationFilm: null,
      trailer: null,
    };
  },
  created: async function () {
    let response = await fetch(
      `${this.$store.state.urlAPI}movie/${this.$route.params.id}?${this.$store.state.apiKey}&language=fr-FR`
    );
    this.informationFilm = await response.json();

    let result;

    let response1 = await fetch(
      `${this.$store.state.urlAPI}movie/${this.$route.params.id}/videos?${this.$store.state.apiKey}&language=fr-FR`
    );
    result = await response1.json();

    this.trailer = `https://www.youtube.com/embed/${result.results[0].key}`;
    // console.log(this.trailer);
  },
  components: { navebar },
  methods: {
    getUrlPict2: function () {
      return this.$store.state.urlPict.poster_path === null
        ? "./image-non-disponible.png"
        : this.$store.state.urlPict +
            `${this.$store.state.urlPict}${this.informationFilm.poster_path}`;
    },
  },
};
</script>
<style scoped>
.position{
  margin-top: 20px;
}
h2{
  color: white;
  font-size: 35px;
}
.align{
  text-align: left;
  margin-left: 30px;
}
.flex{
  display: flex;
  margin-top: 20px;
}
.ajout {
  color: white;
  background-color: green;
  border: none;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  font-weight: bold;
}
.supprimer {
  color: white;
  background-color: red;
  border: none;
  border-radius: 20px;
  padding: 5px 15px 5px 15px;
  font-weight: bold;
}
p,
li {
  color: white;
}
</style>

